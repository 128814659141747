<template>
	<v-row justify="center">
		<v-col cols="12" md="10">
			<v-card>
				<v-img
					height="250px"
					:src="uploadedImageUrl !== '' ? uploadedImageUrl : `https://picsum.photos/id/1018/1280/853`"
				>
					<v-row>
						<v-col class="text-right" cols="12">
							<v-menu bottom left transition="slide-y-transition">
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										icon dark
										v-bind="attrs"
										v-on="on"
									>
										<v-icon>mdi-dots-vertical</v-icon>
									</v-btn>
								</template>
								<v-list>
									<v-list-item>
										<v-list-item-action>
											<v-icon>mdi-file-image-plus</v-icon>
										</v-list-item-action>
										<v-list-item-content>
											<v-list-item-title>Change Header</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</v-list>

							</v-menu>
						</v-col>
					</v-row>
					<v-row class="pa-4" align="center" justify="center">
						<v-col class="text-center">
							<h3 class="text-h5 white--text">{{collectionName}}</h3>
							<span class="grey--text text--lighten-2">{{ collectionDescription }}</span>
						</v-col>
					</v-row>
				</v-img>
				<!-- <v-toolbar color="primary">
					<v-toolbar-title>Collection - {{collectionName}}</v-toolbar-title>
				</v-toolbar> -->
				<v-card-text>

					<v-form ref="addCategory">

						<v-row class="text-center" justify="center" dense>
							
							<!-- Header Image -->
							<v-col cols="12" md="6">
								<v-file-input persistent-hint hint="Upload a banner to appear on the top of collection."
									filled
									:rules="rules"
									accept="image/png, image/jpeg, image/bmp"
									placeholder="Pick a banner image"
									prepend-icon="mdi-camera"
									label="Banner"
									@change="imageChanged"
								></v-file-input>
							</v-col>

							<!-- Collection Name -->
							<v-col cols="12" md="6">
								<v-text-field persistent-hint hint="* Required | Give your Collection a unique name."
									filled
									label="Name*"
									placeholder="Christmas Collection"
									v-model="collectionName"
								></v-text-field>
							</v-col>
						</v-row>

						<v-row>
							<v-col cols="12">
								<v-textarea persistent-hint hint="Optional | Go into the Collection's details."
									filled
									v-model="collectionDescription"
									label="Description"
									auto-grow
								></v-textarea>
							</v-col>
						</v-row>

						<v-row dense>
							<v-col cols="12">
								<!-- Recipe Selectors -->
								<v-autocomplete persistent-hint hint="* Required | Include recipes in your collection."
									return-object
									v-model="collectionRecipes"
									label="Recipes*"
									:items="storeRecipes"
									item-text="name"
									filled
									chips
									multiple
								>
									<template v-slot:selection="data">
										<v-chip
											close
											v-bind="data.attrs"
											:input-value="data.selected"
											@click="data.select"
											@click:close="remove(data.item)"
										>
											<v-avatar left color="blue">
												<v-img v-if="data.item['thumbnail'] !== undefined"
													:src="data.item.thumbnail"
												></v-img>
												<v-icon dark small v-else>
													mdi-glass-cocktail
												</v-icon>
											</v-avatar>
											{{data.item.name}}
										</v-chip>
									</template>
									<template v-slot:item="data">
										<v-list-item-avatar v-if="data.item['thumbnail'] === undefined">
											<v-avatar color="blue">
												<v-icon dark>
													mdi-glass-cocktail
												</v-icon>
											</v-avatar>
										</v-list-item-avatar>
										<v-list-item-avatar v-else>
											<img :src="data.item.thumbnail">
										</v-list-item-avatar>
										<v-list-item-content>
											<v-list-item-title v-html="data.item.name"></v-list-item-title>
											<v-list-item-subtitle v-html="shortenedIngreds(data.item.ingredients)"></v-list-item-subtitle>
										</v-list-item-content>
									</template>
								</v-autocomplete>
							</v-col>
						</v-row>

						<v-row dense>
							<v-col cols="12">
								<v-overflow-btn persistent-hint hint=" * Required | Define the collection's visibility. "
									v-model="collectionVisibility"
									:items="['public', 'public']"
									label="Visibility*"
									filled
								></v-overflow-btn>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>

				<v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="saveCollection">
            Save
            <v-icon right>
              mdi-content-save
            </v-icon>
          </v-btn>
        </v-card-actions>	

			</v-card>
		</v-col>
	</v-row>
</template>

<script>
	import { v4 as uuidv4 } from 'uuid';
	import {  mapState } from 'vuex';
	import { Storage } from '../firebase/storage.js';

	export default {
		name: 'AddCollection',
		data: () => {
			return {
				uploadedImage: '', 
				uploadedImageUrl: '', 
				uploadedImageFileName: uuidv4(),
				uploadedImageDownloadURL: '',

				uploadingImage: false,

				collectionName: '',
				collectionDescription: '',
				collectionRecipes: [],
				collectionVisibility: 'public',

				rules: [
					value => !value || value.size < 5000000 || 'Banner image size should be less than 5 MB!',
				] 
			}
		}, 
		methods: {
			imageChanged(input){
				if(input === null){
					this.uploadedImage = ''; 
					this.uploadedImageUrl = '';
					return;
				}

				this.uploadedImage = input;
				this.uploadedImageUrl = URL.createObjectURL(input);
			}, 
			saveCollection(){
				// Upload the image
				if(this.uploadedImage !== null || this.uploadedImage !== undefined){
					this.uploadingImage = true;

					var metadata = {
						customMetadata: {
							'visibility': this.collectionVisibility
						}
					}
					
					var storageRef = Storage.ref();
					var imagesCollectionsRef = storageRef.child(`images/${this.user.data.id}/collections/${this.uploadedImageFileName}.${this.uploadedImage.name.split('.').pop()}`);
					var imageReference = `images/${this.user.data.id}/collections/${this.uploadedImageFileName}.${this.uploadedImage.name.split('.').pop()}`


					var uploadTask = imagesCollectionsRef.put(this.uploadedImage, metadata);

					uploadTask.on('state_changed', 
						(snapshot) => {
							var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
							if(progress === 100){
								this.uploadingImage = false;
								this.$emit('showAlert', {text: 'Sucessfully uploaded your image!', type: 'success'});
							}
						}, 
						(error) => {
							this.$emit('showAlert', {text: 'There was an error uploading your image. Check the console.', type: 'error'});
							console.log(error);
						}, 
						() => {
							uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
								this.uploadedImageDownloadURL = downloadURL;
								// this.uploadedImageDownloadURL = imageReference;
								this.$emit('showAlert', {text: 'The file was uploaded successfull.', type: 'success'});
								
								this.$store.dispatch('addCollection', {
									banner: this.uploadedImageDownloadURL, 
									name: this.collectionName, 
									description: this.collectionDescription, 
									recipes: this.collectionRecipes
								});
							});
						}
					);
				}

			}, 
			shortenedIngreds(ingredients){
				return ingredients.reduce((prev,curr, curIndex) => {
					let seperator = curIndex === 0 ? '' : ' - '

					if(typeof curr.type === 'object'){
						return prev + seperator + curr.type.name
					}
					return prev + seperator + curr.type
					
				}, '')
			}
		}, 
		computed: {
			...mapState({
				storeRecipes: 'recipes', 
				user: 'user'
			})
		}
	}
</script>